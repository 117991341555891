<template>

<b-card>

    

    <b-overlay
      :show="isLoading"
      rounded="sm"
    >
    
      

      <b-table responsive :items="items" :fields="tableColumns">

        <!-- <template #cell(id)="data">
              {{data.index + 1}}
          </template> -->


      <template #cell(created_at)="data" >
          {{data.value | moment('YY-MM-DD HH:mm:ss')}}
      </template>

      <template #cell(brand_image)="data">
        <b-avatar :src="data.value" />
      </template>

      <template #cell(customers_image)="data">
        <b-avatar :src="data.value" />
      </template>


      <template #cell(status)="data">
        <b-badge @click="changeStatus(data.item.id, data.index)" :variant="status[1][data.value]" style="cursor: pointer;">
          {{ status[0][data.value] }}
        </b-badge>
      </template>

      <template #cell(created_by)="data">
        {{data.value.name}} 
      </template>

      <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item :to="{ name: 'order', params: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">التفاصيل</span>
            </b-dropdown-item>

            
            <!-- <b-dropdown-item @click="editData(data.item)" >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Change WorkShop</span>
            </b-dropdown-item> -->

            <!-- <b-dropdown-item @click="deleteData(data.item.id)" > 
              <feather-icon icon="TrashIcon" />
              <span  class="align-middle ml-50">Delete</span>
            </b-dropdown-item> -->
          </b-dropdown>


          
        </template>
      </b-table>
    
      <div class="demo-spacing-0">
        <b-pagination
          :value="1"
          
          :total-rows="pagination_total"
          :per-page="pagination_per_page"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          @input="(value)=>getData(value)"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>


    </b-overlay>
  </b-card>
</template>


<script>
import { BTable, BCard, BCardText,BAvatar, BOverlay, BBadge, BFormSelect, BDropdownItem, BDropdown, BButton, BPagination } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// import companyData from './companyData'
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import { onMounted } from 'vue'
import DataService  from './data/services'
import Swal from "sweetalert2";

export default {
  components: {
    BTable,
    BCard,
    BCardText,
    BAvatar,
    BOverlay,
    BBadge,
    BFormSelect,
    BDropdownItem,
    BDropdown,
    BButton,
    ToastificationContent,
    BPagination,
  },

  data() {
    return {
      items: [],
      pagination_per_page: 0,
      pagination_total: 0,
      sidebarData: {},
      isAddNewSidebarActive: false,
      isLoading: true,
      companyType: [],
      currentPage: 1,
      perPage: 1,
      rows: 200,
      tableColumns: [
            // { key: 'show_details', label: 'Details'},
            { key: 'id', label:'الرقم', sortable: true },
            { key: 'customer.name', label: 'العميل', sortable: true },
            { key: 'service', label: 'الخدمة', sortable: true},
            // { key: 'statusText', label: 'الحالة', sortable: true },
            { key: 'status', label: 'الحالة', sortable: true },
            // { key: 'year', label: 'Year', sortable: true },
            { key: 'preferredDate', label: 'التاريخ', sortable: true },
            { key: 'preferredTime', label: 'الزمن', sortable: true },
            { key: 'actions', label: 'الإجراءات' },
      ],
      status: [{
        0: 'في انتظار عروض الاسعار', 1: 'مؤكدة', 2: 'تم الوصول لموقع الطلب', 3: 'مكتملة', 4: 'ملغية', 5: 'في الطريق لموقع التسليم', 6: 'تم الوصول لموقع التسليم',
      },
      {
        0: 'light-warning', 1: 'light-primary', 2: 'light-info', 3: 'light-success', 4: 'light-danger', 5: 'light-secondary', 6: 'light-secondary',
      }],
      
    }
  },
  methods: {
    getData(page){
      this.isLoading = true;
      DataService.getAllInActive(page)
      .then(response => {
        this.getOrderInActiveCount()
        this.isLoading = false
        this.items = response.data
        console.log(response.data)
        // this.currentPage = response.data.data.current_page
        this.$toast({
            component: ToastificationContent,
            props: {
              title: 'successfully',
              icon: 'BellIcon',
              text: response.data.responseDescription,
              variant: 'success',
            },
          })
        })
    },
    getOrderInActiveCount() {
      DataService.getOrderInActiveCount()
                  .then(response => {
                    this.pagination_per_page = 20;
                    this.pagination_total = response.data.items;
                  })
    },
    async deleteData(id){

      const result = await Swal.fire({
        title: "هل أنت متأكد ؟",
        text: "لن تتمكن من التراجع عن هذا!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "نعم، احذفه!"
      });

      if (result.isConfirmed) {
        this.isLoading = true;
        DataService.delete(id)
        .then(response => {
          this.isLoading = false

          DataService.getAllInActive(1)
          .then(response => {
            // this.getUserCount()
            this.items = response.data
          })

          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'successfully',
                icon: 'BellIcon',
                text: "تم الحذف بنجاح",
                variant: 'success',
              },
            }
          )
        })
      }
    },
    
    
    editData(data) {
      this.sidebarData = data
      this.toggleDataSidebar(true)
    },
    
    refreshData(newData){
      this.items = newData
    },
    clacItemsId(index){
      return this.items.from + index
    }
  },
  mounted() {
    this.getData(this.currentPage)
  },
  
}
</script>